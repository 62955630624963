import { Oval } from "react-loader-spinner";
import "./preload.css";

const Preload = () => {
    return (
        <div className="preload">
            <div className="innerPreload">
                <Oval width="100" color="#61613F" secondaryColor="#602090" />
            </div>

            {/* <RotatingLines width="100" /> */}
        </div>
    );
};

export default Preload;
